import React, {Component} from 'react';
import {subscribe} from "../../services/newsletter/mailchimp";

class MailChimpSubscribe extends Component {
  state = {
    status: null,
    message: null,
    isSubmitted: false
  };

  submit = (email) => {
    const state = this.state;

    if (state.status !== 'success'
      && state.isSubmitted === false
      && email
      && email.indexOf("@") > -1) {
      this.setState({
        isSubmitted: true
      });

      subscribe(email)
        .then(response => {
          this.setState({
            isSubmitted: false,
            status: response.status,
            message: response.message
          });
        });
    }
  }

  render() {
    const {submit} = this;
    const {status, message} = this.state;
    const {children} = this.props;

    const childrenComponent = React.cloneElement(children, {
      submit: submit,
      status: status,
      message: message
    });

    return (
      childrenComponent
    );
  }
}

export default MailChimpSubscribe;
