import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"

const ButtonStyled = styled.button`
  background-color: ${colors.neonYellow};
  border: none;
  height: 31px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 10px;
  text-transform: uppercase;
  font-size: 8.2px;
  width: 168px;
`


const Button = ({children, onClick}) => {
  return <ButtonStyled onClick={onClick}>{children}</ButtonStyled>
}

export default Button
