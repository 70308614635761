import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GlobalStyle from "../../styles/global"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { BaseCSS } from "styled-bootstrap-grid"
import { GridThemeProvider } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import { gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import Header from "./Header"
import Footer from "./Footer"
import breakpoints from "../../styles/breakpoints"
import { ModalProvider } from "styled-react-modal"
import CookieBanner from "../Core/CookieBanner"
import PreventScreenshot from "../Core/PreventScreenshot"

gsap.registerPlugin(CSSPlugin)

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  @media (min-width: ${breakpoints.md}) {
  }
`
const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  outline: none;
  :focus {
    outline: none;
  }
`

const SpecialModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: 100;
  opacity: 1;
  background-color: white;
  padding: 0px;
  overflow-y: auto;
  @media(min-width: ${breakpoints.lg}) {
    overflow-y: hidden;
  }
`

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
}

const styledTheme = {}

const Layout = ({ children, whiteIcons }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={styledTheme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <GlobalStyle />
          <BaseCSS />
          <GlobalPageContainer>
            <ModalProvider backgroundComponent={SpecialModalBackground}>
            <PreventScreenshot />
            <Header whiteIcons={whiteIcons}/>
            <PageContainer>{children}</PageContainer>
            <Footer />
            <CookieBanner />
            </ModalProvider>
          </GlobalPageContainer>
        </GridThemeProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
