import React from "react"
import styled from "styled-components"
import downArrow from "../../resources/img/ui/down-arrow-fixed.svg"
import straightLine from "../../resources/img/ui/straight-fixed.svg"

const ScrollArrowBtn = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  width: 30px;
  height: 30px;
  div {
    width: 100%;
    height: 100%;
    background-image: url(${downArrow});
    background-repeat: no-repeat;
    background-position: center center;
    animation-delay: 0.5s;
    opacity: 1;
    transition: all 0.2s ease-out;
    cursor: pointer;
    &.first {
      animation: bounceIn 1s forwards;
    }
    &.invisible {
      animation: scrollDown 1.2s forwards;
    }
    &.visible {
      animation: hover 1s forwards;
    }
  }

  @keyframes bounceIn {
    0% {
    }
    10% {
      transform: translateY(-8px);
      opacity: 0;
    }
    70% {
      transform: translateY(2px);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes scrollDown {
    0% {
      opacity: 1;
    }
    10% {
      background-image: url(${straightLine});
    }
    50% {
      opacity: 1;
      background-image: url(${straightLine});
    }
    100% {
      opacity: 0;
      background-image: url(${straightLine});
    }
  }
  @keyframes hover {
    0% {
      opacity: 1;
      background-image: url(${straightLine});
    }
    100% {
      background-image: url(${straightLine});
    }
  }
`

const ScrollArrow = ({handleClick, mouseHover, isBottom, firstLoad}) => {
  return (
    <ScrollArrowBtn>
      <ImageContainer onClick={() => handleClick()}>
        <div
          className={
            (!mouseHover && isBottom ? "invisible" : null) ||
            (firstLoad ? "first" : null) ||
            (mouseHover && isBottom ? "visible" : null)
          }
        />
      </ImageContainer>
    </ScrollArrowBtn>
  )
}

export default ScrollArrow
