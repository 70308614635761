import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import { Col } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ExternalLink from "../Core/ExternalLink"

const MobileCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
`

const ExternalLinkStyled = styled(ExternalLink)`
  text-decoration: none;
  color: ${colors.black};
`
const Title = styled.div`
  text-transform: uppercase;
  padding: ${({ contact }) => (contact ? "8px 0 10px" : "8px 0 4px")};
  font-size: 0.48em;
  letter-spacing: 0.06em;
  @media (min-width: ${breakpoints.md}) {
    padding: ${({ contact }) => (contact ? "8px 0" : "8px 0 4px")};
  }
`

const MobileMenu = ({mobileMenu}) => {
  return (
    <MobileCol sm={4} xs={4}>
    {mobileMenu.map(({ url, title }, index) =>
      title === "instagram" ? (
        <div key={index}>
          <ExternalLinkStyled href={url}>
            <Title>{title}</Title>
          </ExternalLinkStyled>
        </div>
      ) : (
        <div key={index}>
          <LinkStyled to={url}>
            <Title>{title}</Title>
          </LinkStyled>
        </div>
      )
    )}
  </MobileCol>
  )
}

export default MobileMenu
