import React, { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import {getItem, setItem} from "../../services/core/localStorage"

const CookieContainer = styled.div`
  width: 240px;
  height: 219px;
  border: 1px solid ${colors.black};
  background: ${colors.white};
  position: fixed;
  bottom: ${({isHomepage}) => isHomepage ? "69px" : "17px"};
  right: 17px;
  padding: 30px 31.5px;
  text-align: center;
  z-index: 1;
  @media (min-width: ${breakpoints.sm}) {
    bottom: ${({isHomepage}) => isHomepage ? "109.8px" : "57.8px"};
    right: 32px;
  }
`

const Title = styled.h3`
  ${fonts.nhassGroteskRoman};
  font-size: 9px;
  text-transform: uppercase;
  padding-bottom: 18px;
  letter-spacing: 0.4px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 8.5px;
  }
`

const Text = styled.p`
  ${fonts.nhassGroteskRoman};
  font-size: 9px;
  line-height: 14.4px;
  width: 177px;
  padding-bottom: 16.5px;
`

const Accept = styled.button`
  ${fonts.nhassGroteskRoman};
  font-size: 7.36px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  outline: none;
  border: none;
  background-color: ${colors.neonYellow};
  padding: 8.5px 14px;
  cursor: pointer;
`

const CookieBanner = () => {
  const [isCookieActive, setIsCookieActive] = useState(true)
  const [isHomepage, setIsHomepage] = useState(false)
  useEffect(() => {
    if (typeof window !== undefined) {
      setIsCookieActive(getItem("cookieStatus") !== "accepted")
    }
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      let location = window.location
      location.pathname === "/" && setIsHomepage(!isHomepage)
    }
  }, [])

  
  const handleCookieState = () => {
    if (typeof window !== "undefined") {
      setIsCookieActive(!isCookieActive)
      setItem("cookieStatus", "accepted")
    }
  }

  return isCookieActive ? (
    <CookieContainer isHomepage={isHomepage}>
      <Title>Cookie Consent</Title>
      <Text>
        We use cookies (technical and profiling cookies from us and third
        parties) on karlandrew.com to provide you with a better online
        experience. If you select “continue” or access any content of our
        website without customising your choices, you agree to the use of
        cookies.
      </Text>
      <Accept onClick={() => handleCookieState()}>Continue</Accept>
    </CookieContainer>
  ) : null
}

export default CookieBanner
