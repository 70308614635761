import React, { useState, useEffect } from "react"
import styled from "styled-components"
import bolt from "../../resources/img/ui/bolt.svg"

const PreventScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  z-index: 1000;
  top: 0;
  opacity: 0;
  z-index: -10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.25s;
  img {
    width: 80px;
    height: 80px;
  }
  &.visible {
    z-index: 1000;
    opacity: 1;
  }
`

const PreventScreenshot = () => {
  const [isKeyDown, setIsKeyDown] = useState(false)
  let keyPressed = { shift: false, ctrl: false, printKey: false }

  const preventScreenshot = (e) => {
    e.preventDefault()
    if (e.keyCode === 91) {
      keyPressed["ctrl"] = true
    } else if (e.keyCode === 16) {
      keyPressed["shift"] = true
    } else if (e.keyCode === 44) {
      keyPressed["printKey"] = true
    }
    if (
      (keyPressed["ctrl"] && keyPressed["shift"]) ||
      (keyPressed["shift"] && keyPressed["ctrl"]) ||
      keyPressed["printKey"]
    ) {
      setIsKeyDown(!isKeyDown)
    }
  }

  const checkSrcElement = (e) => {
    if (e.srcElement.nodeName !== "INPUT") {
      e.preventDefault()
      preventScreenshot(e)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", checkSrcElement)
    document.addEventListener("keyup", checkSrcElement)
    return () => {
      document.removeEventListener("keydown", checkSrcElement)
      document.removeEventListener("keyup", checkSrcElement)
    }
  })
  return (
    <PreventScreen className={isKeyDown && "visible"}>
      <img src={bolt} alt="bolt" />
    </PreventScreen>
  )
}

export default PreventScreenshot
