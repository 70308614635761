import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Col } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ExternalLink from "../Core/ExternalLink"

const DesktopCol = styled(Col)`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`
const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
`

const ExternalLinkStyled = styled(ExternalLink)`
  text-decoration: none;
  color: ${colors.black};
`
const Title = styled.div`
  text-transform: uppercase;
  font-size: 0.48em;
  letter-spacing: 0.06em;
  @media (min-width: ${breakpoints.md}) {
    padding: ${({ contact }) => (contact ? "8px 0" : "8px 0 5px")};
  }
`

const DesktopMenu = ({ desktopMenu }) => {
  return (
    <>
      {desktopMenu.map((column, index) => (
        <DesktopCol key={index} md={3}>
          {column.map(({ title, url }, index) =>
            title === "instagram" || title === "assisting" ? (
              <div key={index}>
                <ExternalLinkStyled href={url}>
                  <Title>{title}</Title>
                </ExternalLinkStyled>
              </div>
            ) : (
              <div key={index}>
                <LinkStyled to={url}>
                  <Title>{title}</Title>
                </LinkStyled>
              </div>
            )
          )}
        </DesktopCol>
      ))}
    </>
  )
}

export default DesktopMenu
