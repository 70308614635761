import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Button from "../Core/Button"

const SubscribeForm = styled.form`
  max-width: 168px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`

const StyledInput = styled.input`
  ${fonts.nhassGroteskRoman};
  border: none;
  border-bottom: 1px solid ${colors.black};
  padding: 10px 0 4px;
  font-size: 9.5px;
  border-radius: 0;
  width: 100%;
  ::placeholder {
    color: ${colors.grey};
    text-transform: uppercase;
    font-size: 8.5px;
    letter-spacing: 0.4px;;
  }
  :focus {
    outline: none;
  }
`

const SubscribeContainer = styled.div``

const ButtonContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`

const Subscribe = ({ submit, message, status }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")

  const handleOpen = () => setIsOpen(!isOpen)
  const handleChange = (e) => setEmail(e.target.value)
  const handleSubmit = (e) => {
    e.preventDefault()
    submit(email)
    status !== null && setIsOpen(!isOpen)
  }

  return (
    <SubscribeContainer>
      <ButtonContainer isVisible={isOpen === false}>
        <Button onClick={handleOpen}>
          {status === null
            ? "subscribe to our newsletter"
            : status === "success"
            ? "subscribed!"
            : "please try again!"}
        </Button>
      </ButtonContainer>
      <SubscribeForm onSubmit={handleSubmit} isVisible={isOpen}>
        <Button type="submit">Send</Button>
        <StyledInput
          type="email"
          placeholder="Email"
          onChange={handleChange}
          value={email}
        />
      </SubscribeForm>
    </SubscribeContainer>
  )
}

export default Subscribe
