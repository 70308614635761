import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import Subscribe from "../Core/Subscribe"
import MailChimpSubscribe from "../Core/MailChimpSubscribe"
import ScrollArrow from "../Footer/ScrollArrow"
import MobileMenu from "../Footer/MobileMenu"
import DesktopMenu from "../Footer/DesktopMenu"

const ContainerStyled = styled.footer`
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  position: relative;
  padding: ${({ isHomepage }) =>
    isHomepage ? "95px 16px 22px" : "147px 16px 22px"};
  border-top: ${({ isHomepage }) => (isHomepage ? "1px solid transparent" : "1px solid black")};
  @media (min-width: ${breakpoints.md}) {
    height: ${({ isHomepage }) =>
    isHomepage ? "244" : "292"}px;
    padding: ${({ isHomepage }) =>
      isHomepage ? "102px 28px 9px" : "150px 28px 0px"};
  }
`
const RowStyled = styled(Row)`
  padding: 0 6px;
  margin-bottom: 26px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    padding: 0;
  }
`
const Title = styled.div`
  text-transform: uppercase;
  padding: ${({ contact }) => (contact ? "8px 0 10px" : "8px 0 4px")};
  font-size: 0.48em;
  letter-spacing: 0.06em;
  @media (min-width: ${breakpoints.md}) {
    padding: ${({ contact }) => (contact ? "8px 0" : "8px 0 4px")};
  }
`
const MailtoLink = styled.a`
  font-size: 8.5px;
  letter-spacing: 0.07em;
  padding-bottom: 6px;
  border: none;
  display: block;
  text-decoration: none;
  color: ${colors.black};

  &:hover {
    text-decoration: underline;
  }
`
const Text = styled.div`
  font-size: 8.2px;
  letter-spacing: 0.07em;
  padding-bottom: 6px;
`
const CopyRightBlock = styled.div`
  font-size: 8px;
  letter-spacing: 0.07em;
  padding-left: 6px;
  padding-top: 18px;
  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    bottom: 28px;
    padding: 0;
  }
`
const SubscribeBlock = styled(Col)`
  margin-top: 15px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: -20px;
    padding: 16px 0 10px;
    display: flex;
    justify-content: flex-end;
  }
`
const mobileMenu = [
  {
    title: "instagram",
    url: "https://www.instagram.com/karlandrew/?hl=en",
  },
  {
    title: "work",
    url: "/work",
  },
  {
    title: "about",
    url: "/about",
  },
  {
    title: "legal area",
    url: "/legal-area",
  },
]

const desktopMenu = [
  [
    {
      title: "instagram",
      url: "https://www.instagram.com/karlandrew/?hl=en",
    },
    {
      title: "work",
      url: "/work",
    },
  ],
  [
    {
      title: "assisting",
      url: "mailto:enquiries@karlandrew.com",
    },
    {
      title: "about",
      url: "/about",
    },
    {
      title: "legal area",
      url: "/legal-area",
    },
    {
      title: "services",
      url: "/",
    },
  ],
]

const Footer = () => {
  const [isHomepage, setIsHomepage] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [mouseHover, setMouseHover] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      let location = window.location
      location.pathname === "/" && setIsHomepage(!isHomepage)
      setTimeout(() => {
        setFirstLoad(false)
      }, 1000)
    }
  }, [])

  const checkBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setIsBottom(true)
    } else {
      setIsBottom(false)
    }
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", checkBottom)
    }
    return () => {
      window.removeEventListener("scroll", checkBottom)
    }
  })

  const handleHover = (value) => {
    if (typeof window !== undefined) {
      checkBottom()
      setMouseHover(value)
    }
  }

  const handleArrowClick = () => {
    setIsClicked(!isClicked)
    if (typeof window !== undefined) {
      window.scroll({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  return (
    <>
      {isHomepage && <ScrollArrow handleClick={handleArrowClick} mouseHover={mouseHover} isBottom={isBottom} firstLoad={firstLoad}/>}
      <ContainerStyled
        isHomepage={isHomepage}
        onPointerMove={() => handleHover(true)}
        onPointerOut={() => handleHover(false)}
      >
        <RowStyled isHomepage={isHomepage}>
          <Col md={3} sm={8} xs={8}>
            <Title contact>Contact</Title>
            <MailtoLink href={"mailto:enquiries@karlandrew.com"}>
              enquiries@karlandrew.com
            </MailtoLink>
            <Text>London, England</Text>
          </Col>
          <MobileMenu mobileMenu={mobileMenu}/>
          <DesktopMenu desktopMenu={desktopMenu} />
          <SubscribeBlock md={3} sm={12}>
            <MailChimpSubscribe>
              <Subscribe />
            </MailChimpSubscribe>
          </SubscribeBlock>
        </RowStyled>
        <CopyRightBlock>
          Copyright &copy; {new Date().getFullYear()} Karl Andrew. All Rights
          Reserved.
        </CopyRightBlock>
      </ContainerStyled>
    </>
  )
}

export default Footer
