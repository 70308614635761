const lambda = require("../core/lambdaCall");

function subscribe(email) {
  const formData = JSON.stringify({
    email: email,
  });

  return lambda.lambdaCall({
    url: "mailchimp",
    method: "POST",
    body: formData
  })
    .then(response => {
      return Promise.all([response.status, response.json()]);
    })
    .then(([statusCode, body]) => {
      let status;
      let message;

      if (statusCode === 200) {
        status = 'success';
        message = 'Subscribed!';
      } else {
        let detail = false;

        if (typeof body !== 'undefined') {
          detail = body.detail;
        }

        if (statusCode === 400
          && detail
          && typeof detail !== 'undefined'
          && detail.indexOf('already a list member') >= 0) {
          status = 'success';
          message = 'Subscribed!';
        } else {
          status = 'error';
          message = 'Invalid';
        }
      }

      return {
        status: status,
        message: message
      };
    });
}

module.exports = {
  subscribe: subscribe
};
