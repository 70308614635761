import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import nhassGroteskWoff from "../resources/fonts/NHaasGroteskTXPro55Rg.woff"
import nhassGroteskWoff2 from "../resources/fonts/NHaasGroteskTXPro55Rg.woff2"
import nhassGroteskMediumWoff from "../resources/fonts/NHaasGroteskTXPro65Md.woff"
import nhassGroteskMediumWoff2 from "../resources/fonts/NHaasGroteskTXPro65Md.woff2"
import nhassGroteskBoldWoff from "../resources/fonts/NHaasGroteskTXPro75Bd.woff"
import nhassGroteskBoldWoff2 from "../resources/fonts/NHaasGroteskTXPro75Bd.woff2"
import fonts from "./fonts"

const fontFace = (fontFamily, woff, woff2) => {
  return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `
}

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${fontFace("NHassGrotesk Roman", nhassGroteskWoff, nhassGroteskWoff2)}
    ${fontFace("NHassGrotesk Medium", nhassGroteskMediumWoff, nhassGroteskMediumWoff2)}
    ${fontFace("NHassGrotesk Bold", nhassGroteskBoldWoff, nhassGroteskBoldWoff2)}

    body {
        text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      ${fonts.nhassGroteskRoman};
    }
`

export default GlobalStyle
