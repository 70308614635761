const baseUrl = '/.netlify/functions/';

export function lambdaCall(request) {
  let requestUrl = baseUrl + request.url;

  let headers = {
    "Content-Type": "application/json"
  };

  let requestData = {
    method: request.method,
    headers: headers,
    body: request.body,
  };

  return fetch(requestUrl, requestData)
    .then((response) => {
      return response;
    }).catch((err) => {
      console.log(err);
    });
}
