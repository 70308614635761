import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import Menu from "./Menu";
import { Link } from "gatsby";
import logo from "../../resources/img/ui/karl-logo.svg";
import whiteLogo from "../../resources/img/ui/karl-logo-white.png";
import colors from "../../styles/colors";

const HeaderContainer = styled(Container)`
  height: 80px;
  display: flex;
  align-items: center;
  position: absolute;
  @media (min-width: ${breakpoints.md}) {
    height: 120px;
  }
`
const BurgerMenu = styled.div`
  cursor: pointer;
  display: inline-block;
  transition: all 0.32s ease-out;
  margin-left: 14px;
  position: fixed;
  z-index: 10;
  margin-top: -2.5px;
  div {
    width: 24px;
    border-top: 1px solid ${props => props.whiteIcons ? colors.white : colors.black};
    margin: 6px;
    &.line1.toggle {
      transform: rotate(-45deg) translate(-4px, 6px);
    }
    &.line2.toggle {
      opacity: 0;
    }
    &.line3.toggle {
      transform: rotate(45deg) translate(-4px, -6px);
    }
  }

  &.open {
      padding-left: 140px;
    }
  @media (min-width: ${breakpoints.md}) {
    margin-left: 31px;
    &.open {
      padding-left: 110px;
    }
  }
`

const Logo = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  z-index: 1;
`
const LogoImage = styled.div`
  width: 187px;
  img {
    width: 187px;
  }
`

const Header = ({whiteIcons}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <HeaderContainer fluid>
      <BurgerMenu onClick={handleClick} className={isOpen && "open"} whiteIcons={whiteIcons}>
        <div className={`line1 ${isOpen && "toggle"}`}></div>
        <div className={`line2 ${isOpen && "toggle"}`}></div>
        <div className={`line3 ${isOpen && "toggle"}`}></div>
      </BurgerMenu>
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      <Logo to={"/"}>
        <LogoImage>
          <img src={whiteIcons ? whiteLogo : logo} alt="logo" />
        </LogoImage>
      </Logo>
    </HeaderContainer>
  )
}

export default Header
