import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { Link } from "gatsby"

const MenuContainer = styled.div`
  width: 130px;
  height: 100vh;
  background-color: ${colors.white};
  position: fixed;
  left: -130px;
  z-index: 2;
  top: 0;
  transition: all 0.32s ease-out;
  border-right: 1px solid ${colors.black};
  &.open {
    left: 0;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.94);
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
  opacity: 0;
  z-index: -1;
  &.open {
    z-index: 2;
    opacity: 1;
  }
  @media (min-width: 1400px) {
    display: none;
  }
`

const MobileNavLinkContainer = styled(Link)`
  ${fonts.nhassGroteskRoman};
  font-size: 8.4864px;
  text-transform: uppercase;
  height: ${props => props.height}%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${colors.black};
  letter-spacing: 0.8px;
  :hover {
    background-color: ${colors.neonYellow};
  }
`

const menuLinks = [
  { menu: "photography", url: "/work" },
  { menu: "about", url: "/about" }
]

const Menu = ({ isOpen, setIsOpen }) => {
  const menuHeight = 100 / menuLinks.length;
  return (
    <>
      <Overlay className={isOpen && "open"} onClick={() => {setIsOpen(!isOpen)}}/>
      <MenuContainer className={isOpen && "open"}>
        {menuLinks.map(({ menu, url }, index) => (
          <MobileNavLinkContainer
            key={index}
            to={url}
            activeClassName={"active"}
            height={menuHeight}
          >
            {menu}
          </MobileNavLinkContainer>
        ))}
      </MenuContainer>
    </>
  )
}

export default Menu
